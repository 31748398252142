/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    img: "img",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://thepiratebay.se/blog/210"
  }, React.createElement(_components.img, {
    src: "images/the-pirate-bay.jpg",
    alt: "http://cdn.dajaz1.com/wp-content/uploads/2012/02/the-pirate-bay.jpg"
  }))), "\n", React.createElement(_components.p, null, "Me encanta ", React.createElement(_components.a, {
    href: "http://thepiratebay.se",
    title: "The Pirate Bay"
  }, "The Pirate Bay"), ", tienen unas ideas increibles y están a la cabeza del P2P, tienen como objetivo liberarse de todas las restricciones que les imponen los estados con sus ", React.createElement(_components.em, null, "leyes antipiratería"), ", aún conociendo todo lo que han hecho por el libre intercambio de conocimientos e información no dejan de sorprenderme cuando ", React.createElement(_components.a, {
    href: "http://thepiratebay.se/blog/210"
  }, "l"), React.createElement(_components.a, {
    href: "http://thepiratebay.se/blog/210"
  }, "eo esta noticia"), ", donde explican que quieren lanzar una flota de drones con servidores de su página, que se comunicarían por radio a una velocidad de 100 Mb/s hasta una distancia de 50 km."), "\n", React.createElement(_components.p, null, "Como dice su eslogan pretenden ser el sistema mas resiliente de la galaxia, por el camino que van están cerca de conseguirlo y además están demostrando como se pueden aplicar los nuevos avances que estamos viendo ultimamente en electrónica y el cada vez mayor abaratamiento de todos esos productos."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
